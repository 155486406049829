// Variables
@import '_variables';

// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';




// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// CoreUI
@import '~@coreui/coreui-pro/scss/coreui';
@import '~@coreui/icons/scss/coreui-icons';

// @import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

// .navbar-laravel {
//   background-color: #fff;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
// }

@import '_mixins';
@import '_custom';

// body *:not(#collapseOutcomes):not(.card-footer) {
//     display: none
// }

body {
    background-color: transparent;
}

header {
    display: none;
}

footer {
    a {
        display: none;
    }
    &.app-footer {
        background-color: transparent;

    }
}

.sidebar {
    display: none;
}

.breadbar {
    display: none;
}

#am-datePicker {
    display: none;
}

#am-tabs {
    display: none;
}

#am-snapshot {
    border: none;
}

#am-formSubmit {
    display: none;
}

#collapseSnapshotHistory {
    display: none;
}

// #am-gchart {
//     width: 100px;
// }

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    html:not([dir="rtl"]) {
      .sidebar#{$infix}-show,
      .sidebar-show {
        .sidebar {
          margin-left: 0;
        }
        @if $breakpoint != "xs" {
          &.sidebar-fixed {
            .main,
            .app-footer {
              margin-left: 0;
            }
            &.sidebar-compact {
              .main,
              .app-footer {
                margin-left: 0;
              }
            }
            &.sidebar-minimized {
              @include media-breakpoint-down($breakpoint-mobile-down) {
                .main,
                .app-footer {
                  margin-left: 0;
                }
              }
              @include media-breakpoint-up($breakpoint-mobile-up) {
                .main,
                .app-footer {
                  margin-left: 0;
                }
              }
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              left: 0;
            }
            &.sidebar-compact {
              .breadcrumb {
                left: 0;
              }
            }
            &.sidebar-minimized {
              .breadcrumb {
                left: 0;
              }
            }
          }
        }
      }

      .aside-menu-show,
      .aside-menu#{$infix}-show {
        .aside-menu {
          margin-right: 0;
        }
        @if $breakpoint != "xs" {
          &.aside-menu-fixed {
            .main,
            .app-footer {
              margin-right: 0;
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              right: 0;
            }
          }
        }
      }
    }

    html[dir="rtl"] {
      .sidebar#{$infix}-show,
      .sidebar-show {
        .sidebar {
          margin-right: 0;
        }
        @if $breakpoint != "xs" {
          &.sidebar-fixed {
            .main,
            .app-footer {
              margin-right: 0;
            }
            &.sidebar-compact {
              .main,
              .app-footer {
                margin-right: 0;
              }
            }
            &.sidebar-minimized {
              @include media-breakpoint-down($breakpoint-mobile-down) {
                .main,
                .app-footer {
                  margin-right: 0;
                }
              }
              @include media-breakpoint-up($breakpoint-mobile-up) {
                .main,
                .app-footer {
                  margin-right: 0;
                }
              }
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              right: 0;
            }
            &.sidebar-compact {
              .breadcrumb {
                right: 0;
              }
            }
            &.sidebar-minimized {
              .breadcrumb {
                right: 0;
              }
            }
          }
        }
      }

      .aside-menu-show,
      .aside-menu#{$infix}-show {
        .aside-menu {
          margin-left: 0;
        }
        @if $breakpoint != "xs" {
          &.aside-menu-fixed {
            .main,
            .app-footer {
              margin-left: 0;
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              left: 0;
            }
          }
        }
      }
    }

    .sidebar#{$infix}-show,
    .aside-menu#{$infix}-show {
      @keyframes opacity {
        0%   { opacity: 0; }
        100% { opacity: 1; }
      }

      @if $breakpoint == "xs" {
        @include media-breakpoint-down(xs) {
          .main {
            position: relative;
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: $zindex-sticky - 2;
              width: 100%;
              height: 100%;
              content: "";
              background: rgba(0, 0, 0, .7);
              animation: opacity $layout-transition-speed;
            }
          }
        }
      }
    }
  }
}
//
// #collapseOutcomes {
//     display: block;
// }
//
// main {
//     margin-left: 0px;
// }
